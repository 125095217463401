import { TextField } from "@mui/material";
import InputFieldType from "../../enums/InputFieldType";
import BooleanField from "./BooleanField";
import NumberField from "./NumberField";
import ObjectTypeLookup from "./ObjectTypeLookup";
import SignalTypeLookup from "./SignalTypeLookup";
import ProjectTypeLookup from "./ProjectTypeLookup";
import EquipmentTypeLookup from "./EquipmentTypeLookup";
import LookupField from "./LookupField";
import ObjectsLookup from "./ObjectsLookUp";
import ObjectNumbersLookup from "./ObjectNumbersLookUp";
import Lookup from "./Lookup";
import { withConfig } from "../utils";
import { processHasPatternInputValue } from "../../utils/utils";
import { FILTER_TYPES } from "../../dao/types";
import { ROUTES } from "../../enums/Routes";

// Section fields and ids in equipment entity
// Exported for use in Lookup to generate the breadcrumbs
const SECTION_ID = "section_id";
export const SECTION_REFERENCE = InputFieldType.SECTION_REFERENCE;
const SUPPLIED_FROM_ID = "supplied_from";
const SUPPLIED_FROM_REFERENCE = "supplied_from_reference";
// Section fields and ids in cable entity
const SOURCE_SECTION_ID = "source_section_id";
export const SOURCE_SECTION_REFERENCE = "source_section_reference";
const DESTINATION_SECTION_ID = "destination_section_id";
export const DESTINATION_SECTION_REFERENCE = "destination_section_reference";
// Section fields and ids in section entity
const SUPPLIED_FROM_1_ID = "supplied_from_1";
const SUPPLIED_FROM_1_REFERENCE = "supplied_from_1_reference";
const SUPPLIED_FROM_2_ID = "supplied_from_2";
const SUPPLIED_FROM_2_REFERENCE = "supplied_from_2_reference";
const SUPPLIED_FROM_3_ID = "supplied_from_3";
const SUPPLIED_FROM_3_REFERENCE = "supplied_from_3_reference";
// Calculated fields references to be used across the application
export const calculatedFields = [
  SUPPLIED_FROM_REFERENCE,
  SECTION_REFERENCE,
  SUPPLIED_FROM_1_REFERENCE,
  SUPPLIED_FROM_2_REFERENCE,
  SUPPLIED_FROM_3_REFERENCE,
];

// The unique identifier for the calculated section fields is calculated_section
// When we select the calculated section field, we need to set the secondary field which is the relevant section id
const fieldConfigMap = {
  [FILTER_TYPES.equipment]: {
    [SUPPLIED_FROM_REFERENCE]: {
      initialOptionLabel: SUPPLIED_FROM_REFERENCE,
      secondaryFieldNameToBeSet: SUPPLIED_FROM_ID,
      isSecondaryFieldValueAnArray: true,
    },
    default: {
      initialOptionLabel: SECTION_REFERENCE,
      secondaryFieldNameToBeSet: SECTION_ID,
      optionLabel: "parent_equipment_section",
      // This prevents the same equipment from being selected and assigned to itself
      filterFn: (item, entityValues) =>
        item.equipment_reference2 !== entityValues.reference2,
      // Additional filter for the Equipment entity / Section dropdown results to get the unique records.
      additionalFilters: { nested_equipment_id: { value: "", type: "empty" } },
      order: "parent_equipment_section asc",
    },
  },
  [FILTER_TYPES.cable]: {
    [SOURCE_SECTION_REFERENCE]: {
      initialOptionLabel: SOURCE_SECTION_REFERENCE,
      secondaryFieldNameToBeSet: SOURCE_SECTION_ID,
      isSecondaryFieldValueAnArray: true,
    },
    default: {
      initialOptionLabel: DESTINATION_SECTION_REFERENCE,
      secondaryFieldNameToBeSet: DESTINATION_SECTION_ID,
      isSecondaryFieldValueAnArray: true,
    },
  },
  [FILTER_TYPES.section]: {
    [SUPPLIED_FROM_1_REFERENCE]: {
      initialOptionLabel: SUPPLIED_FROM_1_REFERENCE,
      secondaryFieldNameToBeSet: SUPPLIED_FROM_1_ID,
      isSecondaryFieldValueAnArray: true,
    },
    [SUPPLIED_FROM_2_REFERENCE]: {
      initialOptionLabel: SUPPLIED_FROM_2_REFERENCE,
      secondaryFieldNameToBeSet: SUPPLIED_FROM_2_ID,
      isSecondaryFieldValueAnArray: true,
    },
    [SUPPLIED_FROM_3_REFERENCE]: {
      initialOptionLabel: SUPPLIED_FROM_3_REFERENCE,
      secondaryFieldNameToBeSet: SUPPLIED_FROM_3_ID,
      isSecondaryFieldValueAnArray: true,
    },
    default: {
      initialOptionLabel: SECTION_REFERENCE,
      secondaryFieldNameToBeSet: SECTION_ID,
    },
  },
};

const getSectionFieldConfig = (entity, fieldname) => {
  const entityConfig = fieldConfigMap[entity];
  if (!entityConfig) return null;

  const config = entityConfig[fieldname] || entityConfig.default;
  if (!config) return null;
  return withConfig({
    identifier: config.optionLabel ?? "calculated_section",
    initialValueIdentifier: "section_id",
    route: "/calculated-sections",
    optionLabel: config.optionLabel ?? "calculated_section",
    initialOptionLabel: config.initialOptionLabel,
    secondaryFieldNameToBeSet: config.secondaryFieldNameToBeSet,
    isSecondaryFieldValueAnArray: config.isSecondaryFieldValueAnArray,
    dependencies: { objectId: true },
    filterFn: config.filterFn,
    additionalFilters: config.additionalFilters,
    order: config.order ?? "calculated_section asc",
  })(Lookup);
};

const fieldTypes = {
  email: function FormFactoryEmail({
    formStateHandlers,
    fieldSetting,
    inputProps,
    ...props
  }) {
    const { text } = formStateHandlers;
    const fieldConfig = text(fieldSetting);
    return (
      <TextField
        {...{
          ...fieldConfig,
          value: fieldConfig.value.username || fieldConfig.value,
        }}
        {...props}
        inputProps={inputProps}
        label={props.required ? `${props.label} (required)` : props.label}
        error={props.data?.isFieldFailed}
        InputLabelProps={{
          shrink: true,
          required: false,
        }}
      />
    );
  },
  date: function FormFactoryDate({
    formStateHandlers,
    fieldSetting,
    inputProps,
    ...props
  }) {
    const { text } = formStateHandlers;
    const fieldConfig = text(fieldSetting);
    return (
      <TextField
        {...fieldConfig}
        {...props}
        type='date'
        inputProps={inputProps}
        label={props.required ? `${props.label} (required)` : props.label}
        error={props.data?.isFieldFailed}
        InputLabelProps={{
          shrink: true,
          required: false,
        }}
      />
    );
  },
  default: function FormFactoryDefault({
    formStateHandlers,
    fieldSetting,
    inputProps,
    ...props
  }) {
    const { text, raw } = formStateHandlers;
    const defaultFieldConfig = text(fieldSetting);
    const fieldConfig = raw({
      ...defaultFieldConfig,
      onChange: (input) => {
        const value = processHasPatternInputValue(
          input.target.value,
          props?.data?.has_pattern
        );
        fieldSetting.onChange({
          target: {
            fieldname: fieldSetting.name,
            value,
          },
        });
        return value;
      },
    });
    return (
      <TextField
        {...fieldConfig}
        {...props}
        inputProps={inputProps}
        label={
          fieldConfig?.required || props?.data?.required
            ? `${props.label} (required)`
            : props.label
        }
        error={props.data?.isFieldFailed}
        InputLabelProps={{
          shrink: true,
          required: false,
        }}
      />
    );
  },
  [InputFieldType.BOOLEAN]: BooleanField,
  [InputFieldType.NUMBER]: NumberField,
  [InputFieldType.INTEGER]: NumberField,
  [InputFieldType.OBJECT_TYPES]: ObjectTypeLookup,
  [InputFieldType.SIGNAL_TYPES]: SignalTypeLookup,
  [InputFieldType.PROJECT_TYPES]: ProjectTypeLookup,
  [InputFieldType.LOOKUP]: LookupField,
  [InputFieldType.OBJECTS]: ObjectsLookup,
  [InputFieldType.OBJECT_NUMBERS]: ObjectNumbersLookup,
  [InputFieldType.EQUIPMENT_TYPES]: EquipmentTypeLookup,
  [InputFieldType.LOCATION_LIST]: withConfig({
    route: ROUTES.location.list,
    order: "number asc",
    optionLabel: "reference",
    initialOptionLabel: "location_reference",
    initialValueIdentifier: "reference",
    secondaryFieldNameToBeSet: "location_reference",
    dependencies: { objectId: true },
  })(Lookup),
  [InputFieldType.EQUIPMENT_LIST]: withConfig({
    route: ROUTES.equipment.list,
    order: "reference asc",
    optionLabel: "reference2",
    initialOptionLabel: "equipment_reference2",
    dependencies: { objectId: true, systemId: true },
  })(Lookup),
  [InputFieldType.PROJECTS_LIST]: withConfig({
    route: ROUTES.project.list,
    order: "reference asc",
    initialOptionLabel: "project_reference",
    initialValueIdentifier: "reference",
    secondaryFieldNameToBeSet: "project_reference",
    dependencies: { objectId: true },
  })(Lookup),
  [InputFieldType.SYSTEM_NUMBER_LIST]: withConfig({
    route: ROUTES.system.list,
    order: "number asc",
    initialOptionLabel: "system_reference",
    initialValueIdentifier: "reference",
    secondaryFieldNameToBeSet: "system_reference",
    dependencies: { objectId: true, systemGroupId: true },
  })(Lookup),
  [InputFieldType.CABLE_BUNDLE_LIST]: withConfig({
    route: "/cable-bundles", // In ROUTES, it is "/bundles"
    optionLabel: "number",
    initialOptionLabel: "bundle_number",
    dependencies: { objectId: true },
    filterFn: (item) => typeof item?.number === "number",
  })(Lookup),
  [InputFieldType.SYSTEM_GROUP_NUMBER_LIST]: withConfig({
    route: ROUTES.systemGroup.list,
    order: "number asc",
    initialOptionLabel: "system_group_reference",
    initialValueIdentifier: "reference",
    secondaryFieldNameToBeSet: "system_group_reference",
    dependencies: { objectId: true },
  })(Lookup),
  [InputFieldType.CABLE_TYPE_LIST]: withConfig({
    route: ROUTES.cableType.list,
    optionLabel: "type",
    initialOptionLabel: "cable_type",
    filterFn: (item) => item?.type?.length > 0,
  })(Lookup),
  [InputFieldType.DRUM_LIST]: withConfig({
    route: ROUTES.drums.list,
    optionLabel: "number",
    initialOptionLabel: "drum_number",
    dependencies: { objectId: true, projectId: true },
    filterFn: (item) => item?.number,
  })(Lookup),
  [InputFieldType.ORDER_LIST]: withConfig({
    route: ROUTES.order.list,
    optionLabel: "number",
    initialOptionLabel: "order_number",
    dependencies: { objectId: true, projectId: true },
    filterFn: (item) => item?.number,
  })(Lookup),
};

/**
 * get the field type
 * @param {string} type
 * @param {string} fieldname
 * @param {string} entity
 * @returns {object} the field type
 */
export const getFieldTypes = (type, entity, fieldname) => {
  if (!type) return null;

  // Calculated section fields
  if (type === InputFieldType.SECTION_REFERENCE) {
    return getSectionFieldConfig(entity, fieldname);
  }

  return fieldTypes[type];
};
