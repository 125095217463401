import compose from "compose-function";
import { del, patch, post } from "../api/api";
import { sanitizeEntity } from "./../utils/dao";
import { getInlineEditQueryParam } from "../utils/utils";

export const EQUIPMENTS_API_PATH = "/equipments";

/**
 * Update an equipment
 *
 * @param {object[]} equipment
 * @param {string} equipmentId
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%235701/}
 */
export async function patchEquipment(equipment, equipmentId, isInlineEdit) {
  return patch(
    [EQUIPMENTS_API_PATH, equipmentId].join("/") +
      getInlineEditQueryParam(isInlineEdit),
    compose(
      sanitizeEntity("id"),
      sanitizeEntity("object_number"),
      sanitizeEntity("system_group_number"),
      sanitizeEntity("system_number"),
      sanitizeEntity("reference"),
      sanitizeEntity("reference2"),
      sanitizeEntity("project_number"),
      sanitizeEntity("location_number")
    )(equipment)
  );
}

/**
 * Create an equipment
 *
 * @param {object[]} equipment
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%235436/}
 */
export async function addEquipment(equipment) {
  const body = await post(
    EQUIPMENTS_API_PATH,
    compose(sanitizeEntity("system_group_number"))(equipment)
  );
  return body?.response;
}

/**
 * Mark equipment as deleted
 *
 * @param {object[]} equipment
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%235701/}
 */
export async function softDeleteEquipment(equipment) {
  const body = await patch([EQUIPMENTS_API_PATH, equipment.id].join("/"), {
    delete: true,
  });
  return body?.response;
}

/**
 * Unmark equipment as deleted
 *
 * @param {object[]} equipment
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%235701/}
 */
export async function restoreEquipment(equipment) {
  const body = await patch([EQUIPMENTS_API_PATH, equipment.id].join("/"), {
    delete: false,
  });
  return body?.response;
}

/**
 * Remove equipment from database
 *
 * @param {object[]} equipment
 * @returns {object} - response
 * {@link https://anypoint.mulesoft.com/exchange/e352dc95-23bc-4542-a357-94cbe82019f1/e-oasis-api/minor/1.4/console/method/%235962/}
 */
export async function hardDeleteEquipment(equipment) {
  const body = await del([EQUIPMENTS_API_PATH, equipment.id].join("/"));
  return body?.response;
}
