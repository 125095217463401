import React, { useContext } from "react";
import clsx from "clsx";
import { TextField, Autocomplete } from "@mui/material";

import { ejectDeletedEntities, getIdValues, noop } from "./../../utils/utils";
import { LOADING_STATE_PENDING } from "../../config";
import useEntities from "../../hooks/useEntities";
import { GlobalContext } from "../../globalContext";

/**
 * getNameFromValue - Accepts a list of options and a value to search for in the options list.
 * If a matching option is found, it updates the state using setObjectEntity and returns
 * a string representation of the found option.
 * @param {Array} options - The list of options to search in.
 * @param {string} value - The value to search for in the options list.
 * @param {Function} setObjectEntity - The function to update the state with the found option.
 * @returns {string} - A string representation of the found option, or a default string if no option is found.
 */
export function getNameFromValue(options, value, setObjectEntity) {
  if (!value || value === "" || !options.length) return "No options available.";
  // find the object in the options array or return undefined (nothing found)
  const obj = options.find((option) => option?.id === value);
  // guard for undefined (nothing found)
  setObjectEntity(obj);
  return `${obj?.number} (${obj?.name})`;
}

function isEqual(inputOne, inputTwo) {
  return inputOne === inputTwo;
}

export default function GlobalFilterSelect({
  className,
  helperText,
  variant,
  required,
  inputProps,
  setObjectEntity = noop.fn,
  ...props
}) {
  const recordsToFetch = 10000;
  const { objectCreatedCount } = useContext(GlobalContext);
  const { data: objects, loadingState } = useEntities("objects", {
    initialFilters: "",
    initialLimit: recordsToFetch,
    refreshRecords: objectCreatedCount,
  });

  const cleanedObjects = ejectDeletedEntities(objects);

  const fieldProps = {
    ...props,
    id: props.id,
    className: clsx(className),
  };

  const finalInputProps = {
    label: props.label,
    error: props.error,
    id: props.id,
    variant,
    required,
    helperText,
    ...inputProps,
  };

  if (loadingState === LOADING_STATE_PENDING) {
    return <div>Loading objects...</div>;
  }

  return (
    <Autocomplete
      {...fieldProps}
      options={getIdValues(cleanedObjects)}
      getOptionLabel={(value) =>
        getNameFromValue(cleanedObjects, value, setObjectEntity)
      }
      isOptionEqualToValue={isEqual}
      autoSelect={true}
      renderInput={(params) => (
        <TextField {...params} {...finalInputProps} fullWidth />
      )}
    />
  );
}
